import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const BusinessOrderDetails = () => {
  const { id } = useParams(); // Get the order ID from the URL
  const [orderDetails, setOrderDetails] = useState(null);
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(`https://api.payasvinimilk.com/api/businessOrders/getBusinessOrderById/${id}`);
        setOrderDetails(response.data.order); // Assuming the API returns an 'order' object
        setStatus(response.data.order.status); // Initialize status
      } catch (err) {
        console.error('Error fetching order details:', err);
        setError('Failed to fetch order details');
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id]);

  const handleStatusChange = async (newStatus) => {
    try {
      await axios.put(`https://api.payasvinimilk.com/api/businessOrders/updateBusinessOrderStatus/${id}`, { status: newStatus });
      setStatus(newStatus); // Update status locally
      alert('Order status updated successfully!');
    } catch (err) {
      console.error('Error updating order status:', err);
      alert('Failed to update order status. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  if (!orderDetails) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-gray-600">No order found.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      {/* Main Content */}
      <div className="flex-grow overflow-y-auto p-8">
        <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-6">
            {/* Order and User Details */}
            <div className="col-span-2 space-y-8">
              {/* Order Details */}
              <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <h1 className="text-2xl font-semibold text-gray-800 mb-4">Order Details</h1>
                <h2 className="text-2xl font-semibold mb-4">Order ID: {orderDetails._id || 'N/A'}</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <p><strong>Business Name:</strong> {orderDetails.userDetails?.businessName || 'N/A'}</p>
                  <p><strong>Contact Person:</strong> {orderDetails.userDetails?.contactPerson || 'N/A'}</p>
                  <p><strong>Mobile:</strong> {orderDetails.userDetails?.mobile || 'N/A'}</p>
                  <p><strong>Email:</strong> {orderDetails.userDetails?.email || 'N/A'}</p>
                  <p><strong>Shipping Address:</strong> {orderDetails.userDetails?.shippingAddress || 'N/A'}</p>
                  <p><strong>City:</strong> {orderDetails.userDetails?.city || 'N/A'}</p>
                  <p><strong>Pin Code:</strong> {orderDetails.userDetails?.pinCode || 'N/A'}</p>
                  <p><strong>Firm Name:</strong> {orderDetails.firmName || 'N/A'}</p>
                  <p><strong>Order Date:</strong> {new Date(orderDetails.orderDate).toLocaleString() || 'N/A'}</p>
                </div>
              </div>

              {/* User Wallet History */}
              <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold my-4">Wallet History</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <p><strong>Remark:</strong> {orderDetails.walletHistory?.remark || 'N/A'}</p>
                  <p><strong>Receipt ID:</strong> {orderDetails.walletHistory?.receiptId || 'N/A'}</p>
                  <p><strong>Previous Balance:</strong> {orderDetails.walletHistory?.previousBalance || 'N/A'}</p>
                  <p><strong>New Balance:</strong> {orderDetails.walletHistory?.newBalance || 'N/A'}</p>
                  <p><strong>Wallet History Date:</strong> {new Date(orderDetails.walletHistory?.date).toLocaleString() || 'N/A'}</p>
                </div>
              </div>
            </div>

            {/* cart details */}
            <div className="col-span-1 space-y-8">
              <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Cart Details</h2>
                <div className="space-y-4">
                  <div>
                    <p><strong>User ID:</strong></p>
                    <p className="text-gray-600">{orderDetails.userId || 'N/A'}</p>
                  </div>
                  <div>
                    <p><strong>Product ID:</strong></p>
                    <p className="text-gray-600">{orderDetails.productId || 'N/A'}</p>
                  </div>
                  <div>
                    <p><strong>Quantity</strong></p>
                    <p className="text-gray-600">₹{orderDetails.quantity || 'N/A'}</p>
                  </div>
                </div>
              </div>

            {/* Payment and Status */}
              <div className="bg-gray-50 p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Payment Details</h2>
                <div className="space-y-4">
                  <div>
                    <p><strong>Total Price:</strong></p>
                    <p className="text-gray-600">₹{orderDetails.totalPrice || 'N/A'}</p>
                  </div>
                  <div>
                    <p><strong>Status:</strong></p>
                    <p className="text-gray-600">{status}</p>
                  </div>
                </div>
              </div>
              </div>
          </div>
        </div>
      </div>

      {/* Status Change Section */}
      <div className="bg-white shadow-lg rounded-lg p-6 mt-6 max-w-4xl mx-auto">
        <h2 className="text-xl font-semibold text-gray-800 mb-4">Change Status</h2>
        <div className="grid grid-cols-2 sm:grid-cols-5 gap-4">
          <button
            onClick={() => handleStatusChange('Pending')}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition"
          >
            Pending
          </button>
          <button
            onClick={() => handleStatusChange('Processing')}
            className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
          >
            Processing
          </button>
          <button
            onClick={() => handleStatusChange('Shipped')}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
          >
            Shipped
          </button>
          <button
            onClick={() => handleStatusChange('Delivered')}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
          >
            Delivered
          </button>
          <button
            onClick={() => handleStatusChange('Cancelled')}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
          >
            Cancelled
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessOrderDetails;
