import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BusinessWallet = () => {
  // State to hold users fetched from the API
  const [businessUsers, setBusinessUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [businessSelectedUserId, setBusinessSelectedUserId] = useState('');
  const [businessAmountToAdd, setBusinessAmountToAdd] = useState('');
  const [businessRemarks, setBusinessRemarks] = useState('');
  const [businessReceiptId, setBusinessReceiptId] = useState('');

  // Fetch users from API on component mount
  useEffect(() => {
    const fetchBusinessUsers = async () => {
      try {
        const response = await axios.get('https://api.payasvinimilk.com/api/distribution/');
        setBusinessUsers(response.data); // Assuming response data contains the array of business users
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch business users.');
        setLoading(false);
      }
    };

    fetchBusinessUsers();
  }, []);

  // Function to handle adding money to a selected user's wallet
  const addMoneyToBusinessWallet = async () => {
    if (businessSelectedUserId !== '' && businessAmountToAdd !== '') {
      try {

        
        // Update the user's wallet by making an API call
        await axios.put(`https://api.payasvinimilk.com/api/distribution/${businessSelectedUserId}/wallet`, {
          wallet: Number(businessAmountToAdd),
          remark: businessRemarks, // Include remark
          receiptId: businessReceiptId ,// Include receipt ID
          transactionType: 'credit'
        });

        // Update local state with the new wallet balance
        setBusinessUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === businessSelectedUserId
              ? { ...user, wallet: user.wallet + Number(businessAmountToAdd) }
              : user
          )
        );
        clearForm(); // Clear input after adding money
      } catch (error) {
        console.error('Failed to update wallet:', error);
      }
    }
  };

  // Function to clear the form fields
  const clearForm = () => {
    setBusinessSelectedUserId('');
    setBusinessAmountToAdd('');
    setBusinessRemarks('');
    setBusinessReceiptId('');
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Filter active users for the dropdown
  const activeBusinessUsers = businessUsers.filter(user => user.status === 'active');

  return (
    <div className="min-h-screen bg-gray-100 p-8">

      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl mb-4">Add Money to Wallet</h2>

        {/* User Dropdown */}
        <div className="mb-4">
          <label className="block mb-2 font-semibold">Select User</label>
          <select
            value={businessSelectedUserId}
            onChange={(e) => setBusinessSelectedUserId(e.target.value)}
            className="w-full p-2 border rounded-lg"
          >
            <option value="" disabled>Select a User</option>
            {activeBusinessUsers.map((user) => (
              <option key={user._id} value={user._id}>
                {user.contactPerson} ( {user.businessName} ) {/* Replace name with contactPerson */}
              </option>
            ))}
          </select>
        </div>

        {/* Amount Input */}
        <div className="mb-4">
          <label className="block mb-2 font-semibold">Amount to Add</label>
          <input
            type="number"
            placeholder="Enter amount"
            className="w-full p-2 border rounded-lg"
            value={businessAmountToAdd}
            onChange={(e) => setBusinessAmountToAdd(e.target.value)}
          />
        </div>

        {/* Remarks (Optional) */}
        <div className="mb-4">
          <label className="block mb-2 font-semibold">Remarks (Optional)</label>
          <input
            type="text"
            placeholder="Enter remarks (optional)"
            className="w-full p-2 border rounded-lg"
            value={businessRemarks}
            onChange={(e) => setBusinessRemarks(e.target.value)}
          />
        </div>

        {/* Receipt ID (Optional) */}
        <div className="mb-4">
          <label className="block mb-2 font-semibold">Receipt ID (Optional)</label>
          <input
            type="text"
            placeholder="Enter receipt ID (optional)"
            className="w-full p-2 border rounded-lg"
            value={businessReceiptId}
            onChange={(e) => setBusinessReceiptId(e.target.value)}
          />
        </div>

        {/* Submit Button */}
        <button
          className="bg-green-500 text-white px-4 py-2 rounded-lg"
          onClick={addMoneyToBusinessWallet}
          disabled={businessSelectedUserId === '' || businessAmountToAdd === ''}
        >
          Add Money
        </button>
      </div>

      {/* List of Users */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl mb-4">Users and Wallet Balances</h2>
        <ul>
          {businessUsers.map((user) => (
            <li
              key={user._id}
              className="flex justify-between items-center mb-4 p-4 bg-gray-50 rounded-lg"
            >
              <div>
                <p className="font-bold">{user.contactPerson}</p> {/* Show contactPerson */}
                <p>Wallet Balance: ₹{user.wallet}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>

    </div>
  );
};

export default BusinessWallet;
