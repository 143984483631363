import React, { useState, useEffect } from 'react';

const BusinessUsers = () => {
  // State to manage users and requests
  const [businessUsers, setBusinessUsers] = useState([]);
  const [businessPendingRequests, setBusinessPendingRequests] = useState([]);
  const [businessShowDetails, setBusinessShowDetails] = useState(null);
  const [userType, setUserType] = useState('');
  const [selectedUserType, setSelectedUserType] = useState('');
  const [userTypes, setUserTypes] = useState([]);
  const [showUsers, setShowUsers] = useState(false); 

  // Function to handle adding a user type
  const addUserType = async () => {
    if (userType.trim() === '') {
      alert('Please enter a user type');
      return;
    }

    try {
      const response = await fetch('https://api.payasvinimilk.com/api/userType/addUserType', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ type: userType }), // Send userType to the backend
      });

      if (!response.ok) {
        throw new Error('Failed to add user type');
      }

      const newUserType = await response.json();
      setUserTypes([...userTypes, newUserType]); // Add new user type to the list
      setUserType(''); // Reset the input field
    } catch (error) {
      console.error('Error adding user type:', error);
    }
  };

  // Function to fetch all user types
  const fetchUserTypes = async () => {
    try {
      const response = await fetch('https://api.payasvinimilk.com/api/userType/getUserTypes');
      const data = await response.json();
      setUserTypes(data); // Set fetched user types
    } catch (error) {
      console.error('Error fetching user types:', error);
    }
  };

  // Function to handle deleting a user type
  const deleteUserType = async (userTypeId) => {
    try {
      await fetch(`https://api.payasvinimilk.com/api/userType/deleteUserType/${userTypeId}`, {
        method: 'DELETE',
      });

      // Update state after deleting the user type
      setUserTypes(userTypes.filter((type) => type._id !== userTypeId));
    } catch (error) {
      console.error('Error deleting user type:', error);
    }
  };

  // Toggle showing users
  const handleShowUsers = () => {
    setShowUsers(!showUsers); // Toggle show/hide users
  };

  // Fetch data from the API when component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://api.payasvinimilk.com/api/distribution/');
        const data = await response.json();

        // Filter active users and pending requests based on `status`
        const activeUsers = data.filter(user => user.status === 'active');
        const pendingRequests = data.filter(user => user.status !== 'active'); // new or waiting for approval

        setBusinessUsers(activeUsers);
        setBusinessPendingRequests(pendingRequests);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
    fetchUserTypes();
  }, []);

  // Function to approve a distributorship request
  const approveBusinessRequest = async (userId , selectedUserType) => {
    try {
      // Call the updateDistributionStatus API to approve the user
      const response = await fetch(`https://api.payasvinimilk.com/api/distribution/${userId}/status`, {
        method: 'PATCH', // Change status to active
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status: 'active' , userType: selectedUserType }), // Send the status update
      });
  
      if (!response.ok) {
        throw new Error('Failed to update status');
      }
  
      // Find the approved user from pending requests
      const approvedUser = businessPendingRequests.find((user) => user._id === userId);
  
      // Send the data to create the user using the distribution data, including the password
      const createUserResponse = await fetch('https://api.payasvinimilk.com/api/user/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name : approvedUser.businessName,
          userId: approvedUser._id, // Use distribution ID as user ID
          email: approvedUser.email,
          password: approvedUser.password, // Use the password from the distribution details
         
        }),
      });
  
      if (!createUserResponse.ok) {
        throw new Error('Failed to create user');
      }
  
      // Update the frontend state if both API calls were successful
      setBusinessUsers([...businessUsers, { ...approvedUser, status: 'active' }]);
      setBusinessPendingRequests(businessPendingRequests.filter((user) => user.id !== userId));
  
    } catch (error) {
      console.error('Error approving request and creating user:', error);
    }
  };
  

  // Function to toggle showing more details for pending requests
  const toggleBusinessDetails = (userId) => {
    setBusinessShowDetails(businessShowDetails === userId ? null : userId);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">

      {/* Add User Type */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl mb-4">Add User Type</h2>
        {/* Input for new user type */}
        <input
          type="text"
          value={userType}
          onChange={(e) => setUserType(e.target.value)}
          placeholder="Enter User Type"
          className="border p-2 mb-4 rounded w-full"
        />
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          onClick={addUserType}
        >
          Add User Type
        </button>

        {/* Button to show/hide users */}
        <button
          className="bg-green-500 text-white px-4 py-2 rounded-lg ml-4"
          onClick={handleShowUsers}
        >
          {showUsers ? 'Hide Users' : 'Show Users'}
        </button>

        {/* List of user types */}
        {showUsers && (
          <div className="mt-6">
            <h3 className="text-xl mb-4">All User Types</h3>
            <ul>
              {userTypes.map((type) => (
                <li
                  key={type._id}
                  className="flex justify-between items-center p-4 bg-gray-50 rounded-lg mb-2"
                >
                  <span>{type.type}</span>
                  <button
                    className="bg-red-500 text-white px-4 py-2 rounded-lg"
                    onClick={() => deleteUserType(type._id)}
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Pending Distributor Requests */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl mb-4">Pending Distributor Requests</h2>
        <ul>
          {businessPendingRequests.length > 0 ? (
            businessPendingRequests.map((user) => (
              <li key={user.id} className="mb-4 p-4 bg-gray-50 rounded-lg">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-bold">{user.businessName} ({user.email})</p>
                    <p>{user.role}</p>
                  </div>

                  {/* Dropdown for selecting user type */}
                  <select
                    className="border p-2 mr-4 rounded"
                    onChange={(e) => setSelectedUserType(e.target.value)}
                    value={selectedUserType}
                  >
                    <option value="">Select User Type</option>
                    {userTypes.map((type) => (
                      <option key={type._id} value={type._id}>
                        {type.type}
                      </option>
                    ))}
                  </select>

                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg mr-4"
                    onClick={() => toggleBusinessDetails(user.id)}
                  >
                    {businessShowDetails === user.id ? 'Hide Details' : 'Show Details'}
                  </button>
                  <button
                    className="bg-green-500 text-white px-4 py-2 rounded-lg"
                    onClick={() => approveBusinessRequest(user._id , selectedUserType)}
                  >
                    Approve
                  </button>
                </div>
                {/* Show additional details if button clicked */}
                {businessShowDetails === user.id && (
                  <div className="mt-4 p-4 bg-white shadow-md rounded-lg">
                    <p><strong>Business Name:</strong> {user.businessName || "Not Provided"}</p>
                    <p><strong>Contact Person:</strong> {user.contactPerson || "Not Provided"}</p>
                    <p><strong>Country:</strong> {user.country || "Not Provided"}</p>
                    <p><strong>State:</strong> {user.state || "Not Provided"}</p>
                    <p><strong>City:</strong> {user.city || "Not Provided"}</p>
                    <p><strong>Pin Code:</strong> {user.pinCode || "Not Provided"}</p>
                    <p><strong>GST Number:</strong> {user.gstNumber || "Not Provided"}</p>
                    <p><strong>GSTIN Scheme:</strong> {user.gstinScheme || "Not Provided"}</p>
                    <p><strong>Shipping Address:</strong> {user.shippingAddress || "Not Provided"}</p>
                    <p><strong>Mobile:</strong> {user.mobile || "Not Provided"}</p>
                    <p><strong>Email:</strong> {user.email || "Not Provided"}</p>
                    <p><strong>Status:</strong> {user.status || "Not Provided"}</p>
                    <p><strong>Created At:</strong> {new Date(user.createdAt).toLocaleString() || "Not Provided"}</p>
                  </div>
                )}
              </li>
            ))
          ) : (
            <p>No pending requests.</p>
          )}
        </ul>
      </div>

      {/* Existing Users */}
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-2xl mb-4">Existing Users</h2>
        <ul>
          {businessUsers.map((user) => (
            <li key={user.id} className="mb-4 p-4 bg-gray-50 rounded-lg">
              <p className="font-bold">{user.businessName} ({user.email})</p>
              <p>Owner: {user.contactPerson}  ({user.mobile})</p>
              <p>Status: {user.status}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BusinessUsers;
