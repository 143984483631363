import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const BusinessOrdersPage = () => {
  const [businessOrders, setBusinessOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBusinessOrders = async () => {
      try {
        const response = await axios.get('https://api.payasvinimilk.com/api/businessOrders/getAllBusinessOrders');
        setBusinessOrders(response.data.BusinessOrders);
      } catch (err) {
        console.error('Error fetching business orders:', err);
        setError('Failed to fetch business orders');
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessOrders();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const handleViewDetails = (id) => {
    navigate(`/businessOrders-details/${id}`);  // Assuming this is the route for viewing details
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Business Orders</h1>
      <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md text-center">
        <thead>
        <tr className="bg-gray-100 border-b">
            <th className="py-2 px-4 border-b">Sr No.</th>
            <th className="py-2 px-4 border-b">Order ID</th>
            <th className="py-2 px-4 border-b">Date</th>
            <th className="py-2 px-4 border-b">Status</th>
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Total</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          
        {businessOrders.length > 0 ? (
          businessOrders.map((order, index) => (
            <tr key={order._id}>
              <td className="py-2 px-4 border-b">{index + 1}</td> {/* Sr No. */}
              <td className="py-2 px-4 border-b">{order._id}</td> {/* Order ID */}
              <td className="py-2 px-4 border-b">
                  {order.orderDate 
                    ? new Date(order.orderDate).toISOString().split('T')[0] 
                    : 'N/A'
                  }
                </td>
              <td className="py-2 px-4 border-b">{order.status || 'N/A'}</td> {/* Status */}
              <td className="py-2 px-4 border-b">{order.userDetails?.contactPerson || 'N/A'}</td> {/* Name */}
              <td className="py-2 px-4 border-b">${order.totalPrice || 'N/A'}</td> {/* Total */}
              <td className="py-2 px-4 border-b">
                <button
                  onClick={() => handleViewDetails(order._id)}
                  className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                  >
                    View
                  </button>
              </td> {/* View More Details button */}
            </tr>
          ))) : (
            <tr>
              <td colSpan="6" className="py-2 px-4 text-center text-gray-500">No orders found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BusinessOrdersPage;
