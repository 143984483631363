import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddBanner = () => {
  const [selectedFiles, setSelectedFiles] = useState([]); // Store files before upload
  const [banners, setBanners] = useState([]);
  const [isUploading, setIsUploading] = useState(false); // Track upload status

  // Fetch banners on load
  useEffect(() => {
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    try {
      const response = await axios.get(`https://api.payasvinimilk.com/api/banner/getBanners`);
      setBanners(response.data);
    } catch (error) {
      console.error('Error fetching banners:', error);
    }
  };

  // Store selected files in state
  const handleImageSelect = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
  };

  // Upload images on save
  const handleSubmit = async () => {
    const formData = new FormData();
    selectedFiles.forEach((file) => formData.append('images', file));
    setIsUploading(true);

    try {
      const response = await axios.post(`https://api.payasvinimilk.com/api/banner/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      const uploadedImages = response.data.map((file) => file.url);
      setSelectedFiles([]); // Clear selected files after upload
      alert('Images uploaded successfully');
      fetchBanners();
    } catch (error) {
      console.error('Error uploading images:', error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this banner?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`https://api.payasvinimilk.com/api/banner/deleteBanner/${id}`);
      setBanners(banners.filter((banner) => banner._id !== id));
      alert('Banner deleted successfully.');
    } catch (error) {
      console.error('Error deleting banner:', error);
    }
  };

  return (
    <div className="container mx-auto p-6 space-y-8">
      <h2 className="text-3xl font-semibold text-gray-800 text-center">Manage Banners</h2>
      
      {/* Image Upload Section */}
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-xl font-semibold text-gray-700 mb-2">Add Banner Images</h3>
        <p className="text-sm text-gray-500 mb-4">You can only add banners with indices from 0 to 4 (a maximum of 5 images allowed).</p>
        <input
          type="file"
          multiple
          accept="image/*"
          onChange={handleImageSelect}
          className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer p-2 mb-4"
        />
        <button
          onClick={handleSubmit}
          disabled={isUploading || selectedFiles.length === 0} // Disable while uploading or no files selected
          className={`w-1/5 py-2 rounded-md transition duration-200 ${
            isUploading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 text-white hover:bg-blue-700'
          }`}
        >
          {isUploading ? 'Uploading...' : 'Save Banners'}
        </button>
      </div>

      {/* Banners Table Section */}
      <div className="bg-white rounded-lg shadow-lg p-6 overflow-x-auto">
        <h3 className="text-xl font-semibold text-gray-700 mb-4 text-center">Uploaded Banners</h3>
        <table className="min-w-full bg-white border rounded-lg">
          <thead>
            <tr className="bg-gray-200">
              <th className="py-3 px-4 text-left font-semibold text-gray-600 border-b">Index</th>
              <th className="py-3 px-4 text-left font-semibold text-gray-600 border-b">Image</th>
              <th className="py-3 px-4 text-left font-semibold text-gray-600 border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {banners.map((banner, index) => (
              <tr key={banner._id} className="hover:bg-gray-100 transition duration-150">
                <td className="py-3 px-4 border-b text-gray-700">{banner.index}</td>
                <td className="py-3 px-4 border-b">
                  <img src={banner.url} alt={`Banner ${index + 1}`} className="w-20 h-20 object-cover rounded-md" />
                </td>
                <td className="py-3 px-4 border-b">
                  <button
                    className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                    onClick={() => handleDelete(banner._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AddBanner;
