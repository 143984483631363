import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const OrderDetail = () => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [status, setStatus] = useState('');

    useEffect(() => {
        const fetchOrder = async () => {
            try {
                const response = await axios.get(`https://api.payasvinimilk.com/api/orders/${orderId}`);
                setOrder(response.data.order);
                setStatus(response.data.order.status);
            } catch (error) {
                console.error('Error fetching order details:', error);
            }
        };

        fetchOrder();
    }, [orderId]);

    const handleStatusChange = async (newStatus) => {
        try {
            await axios.put(`https://api.payasvinimilk.com/api/orders/${orderId}/status`, { status: newStatus });
            setStatus(newStatus);
            alert('Order status updated successfully!');
        } catch (error) {
            console.error('Error updating order status:', error);
            alert('Failed to update order status. Please try again.');
        }
    };

    if (!order) return <div className="text-center py-8 text-gray-600">Loading...</div>;

    return (
        <div className="min-h-screen flex flex-col bg-gray-100">
            {/* Main Content */}
            <div className="flex-grow overflow-y-auto p-8">
                <div className="max-w-6xl mx-auto bg-white shadow-lg rounded-lg">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-6">

                        {/* Order and User Details */}
                        <div className="col-span-2 space-y-8">
                            {/* Order Details */}
                            <div className="bg-gray-50 p-6 rounded-lg">
                                <h1 className="text-2xl font-semibold text-gray-800 mb-4">Order Details</h1>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">Order ID:</p>
                                        <p className="text-gray-600">{order._id}</p>
                                    </div>
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">User ID:</p>
                                        <p className="text-gray-600">{order.userId}</p>
                                    </div>
                                </div>
                            </div>

                            {/* User Details */}
                            <div className="bg-gray-50 p-6 rounded-lg">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">User Details</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">Name:</p>
                                        <p className="text-gray-600">{order.userDetails.name}</p>
                                    </div>
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">Business Name:</p>
                                        <p className="text-gray-600">{order.userDetails.businessName}</p>
                                    </div>
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">Mobile No:</p>
                                        <p className="text-gray-600">{order.userDetails.mobileNo}</p>
                                    </div>
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">Email:</p>
                                        <p className="text-gray-600">{order.userDetails.email}</p>
                                    </div>
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">Postal Address:</p>
                                        <p className="text-gray-600">{order.userDetails.postalAddress}</p>
                                    </div>
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">Web Address:</p>
                                        <a
                                            href={order.userDetails.webAddress}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-blue-500 hover:underline"
                                        >
                                            {order.userDetails.webAddress}
                                        </a>
                                    </div>
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">Other Details:</p>
                                        <p className="text-gray-600">{order.userDetails.otherDetails}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Cart Items and Payment Details */}
                        <div className="col-span-1 space-y-8">
                            {/* Cart Items */}
                            <div className="bg-gray-50 p-6 rounded-lg">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">Cart Items</h2>
                                <ul className="space-y-4">
                                    {order.cartItems.map(item => (
                                        <li key={item._id} className="p-4 border border-gray-200 rounded-lg shadow-sm">
                                            <p className="text-lg font-medium text-gray-700">Product ID:</p>
                                            <p className="text-gray-600">{item.productId}</p>
                                            <p className="text-lg font-medium text-gray-700">Quantity:</p>
                                            <p className="text-gray-600">{item.quantity}</p>
                                            <p className="text-lg font-medium text-gray-700">Price(per piece):</p>
                                            <p className="text-gray-600">₹{item.price}</p>
                                            {item.dieShape && (
                                                <p className="text-lg font-medium text-gray-700">Die Shape:</p>
                                            )}
                                            <p className="text-gray-600">{item.dieShape}</p>
                                            {item.paperType && (
                                                <p className="text-lg font-medium text-gray-700">Paper Type:</p>
                                            )}
                                            <p className="text-gray-600">{item.paperType}</p>
                                            {item.windowCutting && (
                                                <p className="text-lg font-medium text-gray-700">Window Cutting:</p>
                                            )}
                                            <p className="text-gray-600">{item.windowCutting}</p>
                                            {item.flapOpening && (
                                                <p className="text-lg font-medium text-gray-700">Flap Opening:</p>
                                            )}
                                            <p className="text-gray-600">{item.flapOpening}</p>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            {/* Payment Details */}
                            <div className="bg-gray-50 p-6 rounded-lg">
                                <h2 className="text-xl font-semibold text-gray-800 mb-4">Payment Details</h2>
                                <div className="space-y-4">
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">Total Price:</p>
                                        <p className="text-gray-600">₹{order.totalPrice}</p>
                                    </div>
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">Voucher Code:</p>
                                        <p className="text-gray-600">{order.voucherCode}</p>
                                    </div>
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">Order Date:</p>
                                        <p className="text-gray-600">{new Date(order.orderDate).toLocaleString()}</p>
                                    </div>
                                    <div>
                                        <p className="text-lg font-medium text-gray-700">Status:</p>
                                        <p className="text-gray-600">{status}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Status Change Section */}
            <div className="bg-white shadow-lg rounded-lg p-6 mt-6 max-w-4xl mx-auto">
                <h2 className="text-xl font-semibold text-gray-800 mb-4">Change Status</h2>
                <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
                    <button
                        onClick={() => handleStatusChange('Processing')}
                        className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 transition"
                    >
                        Processing
                    </button>
                    <button
                        onClick={() => handleStatusChange('Shipped')}
                        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                    >
                        Shipped
                    </button>
                    <button
                        onClick={() => handleStatusChange('Delivered')}
                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition"
                    >
                        Delivered
                    </button>
                    <button
                        onClick={() => handleStatusChange('Cancelled')}
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
                    >
                        Cancelled
                    </button>
                </div>
            </div>
        </div>

    );
};

export default OrderDetail;
