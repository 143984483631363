import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DieShapes = () => {
  const [dieShapes, setDieShapes] = useState([]);
  const [newDieShape, setNewDieShape] = useState('');
  const [editingShape, setEditingShape] = useState(null);

  useEffect(() => {
    fetchDieShapes();
  }, []);

  const fetchDieShapes = async () => {
    try {
      const response = await axios.get('https://api.payasvinimilk.com/api/dieshapes');
      setDieShapes(response.data);
    } catch (error) {
      console.error('Error fetching die shapes:', error);
    }
  };

  const addDieShape = async () => {
    if (!newDieShape.trim()) return alert('Shape name is required');

    try {
      const response = await axios.post('https://api.payasvinimilk.com/api/dieshapes', { name: newDieShape });
      setDieShapes([...dieShapes, response.data]);
      setNewDieShape('');
    } catch (error) {
      console.error('Error adding die shape:', error);
    }
  };

  const deleteDieShape = async (id) => {
    try {
      await axios.delete(`https://api.payasvinimilk.com/api/dieshapes/${id}`);
      setDieShapes(dieShapes.filter((shape) => shape._id !== id));
    } catch (error) {
      console.error('Error deleting die shape:', error);
    }
  };

  const updateDieShape = async (id, name) => {
    try {
      await axios.put(`https://api.payasvinimilk.com/api/dieshapes/${id}`, { name });
      setDieShapes(dieShapes.map((shape) => (shape._id === id ? { ...shape, name } : shape)));
      setEditingShape(null);
    } catch (error) {
      console.error('Error updating die shape:', error);
    }
  };

  const handleEdit = (shape) => {
    setEditingShape({ ...shape });
  };

  const handleEditChange = (e) => {
    setEditingShape({ ...editingShape, name: e.target.value });
  };

  const handleEditSubmit = (e) => {
    if (e.key === 'Enter' && editingShape) {
      updateDieShape(editingShape._id, editingShape.name);
    }
  };

  const handleAddShapeSubmit = (e) => {
    if (e.key === 'Enter') {
      addDieShape();
    }
  };

  return (
    <div className=" bg-gray-100 flex justify-center items-center">
      <div className="max-w-full w-full bg-white shadow-md rounded-md p-6">
        <h1 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Manage Die Shapes</h1>

        {/* Form to Add a New Die Shape */}
        <div className="mb-4 flex items-center">
          <input
            type="text"
            placeholder="Enter die shape name"
            value={newDieShape}
            onChange={(e) => setNewDieShape(e.target.value)}
            onKeyDown={handleAddShapeSubmit}
            className="flex-grow border border-gray-300 rounded-md px-3 py-1 mr-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={addDieShape}
            className="bg-blue-500 text-white px-4 py-1 rounded-md hover:bg-blue-600 transition-colors duration-200"
          >
            Add
          </button>
        </div>

        {/* Table of Die Shapes */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200 text-sm">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-left font-medium text-gray-700 border border-gray-200">ID</th>
                <th className="px-4 py-2 text-left font-medium text-gray-700 border border-gray-200">Name</th>
                <th className="px-4 py-2 font-medium text-gray-700 border border-gray-200">Actions</th>
              </tr>
            </thead>
            <tbody>
              {dieShapes.length === 0 ? (
                <tr>
                  <td colSpan="3" className="text-center py-4 text-gray-500">No die shapes available.</td>
                </tr>
              ) : (
                dieShapes.map((shape) => (
                  <tr key={shape._id} className="border-t border-gray-200">
                    <td className="px-4 py-2 border-r border-gray-200">{shape._id}</td>
                    <td className="px-4 py-2 border-r border-gray-200">
                      {editingShape && editingShape._id === shape._id ? (
                        <input
                          type="text"
                          value={editingShape.name}
                          onChange={handleEditChange}
                          onKeyDown={handleEditSubmit}
                          className="border-b border-gray-300 focus:outline-none focus:border-blue-500"
                        />
                      ) : (
                        <span>{shape.name}</span>
                      )}
                    </td>
                    <td className="px-4 py-2 flex space-x-2 justify-center">
                      <button
                        onClick={() => handleEdit(shape)}
                        className="bg-yellow-500 text-white px-2 py-1 rounded-md hover:bg-yellow-600 transition-colors duration-200"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => deleteDieShape(shape._id)}
                        className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600 transition-colors duration-200"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DieShapes;
