import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Categories = () => {
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [newCategoryImage, setNewCategoryImage] = useState(null);
  const [subCategories, setSubCategories] = useState({});
  const [newSubCategoryName, setNewSubCategoryName] = useState('');
  const [newSubCategoryImage, setNewSubCategoryImage] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [isAddingSubCategory, setIsAddingSubCategory] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState(null);
  const [editCategoryName, setEditCategoryName] = useState('');
  const [editCategoryImage, setEditCategoryImage] = useState(null);
  
  // State for editing subcategory
  const [editSubCategoryId, setEditSubCategoryId] = useState(null);
  const [editSubCategoryName, setEditSubCategoryName] = useState('');
  const [editSubCategoryImage, setEditSubCategoryImage] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://api.payasvinimilk.com/api/categories/getCategories');
        setCategories(response.data);
        const subCategoriesMap = response.data.reduce((acc, category) => {
          acc[category._id] = category.subcategory || [];
          return acc;
        }, {});
        setSubCategories(subCategoriesMap);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  const handleAddCategory = async (e) => {
    e.preventDefault();
    if (newCategoryName.trim() && newCategoryImage) {
      setIsAddingCategory(true);
      const formData = new FormData();
      formData.append('name', newCategoryName.trim());
      formData.append('image', newCategoryImage);

      try {
        const response = await axios.post('https://api.payasvinimilk.com/api/categories/createCategory', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setCategories([...categories, response.data]);
        setSubCategories({ ...subCategories, [response.data._id]: [] });
        setNewCategoryName('');
        setNewCategoryImage(null);
        alert('Category added successfully!');
      } catch (error) {
        console.error('Error adding category:', error);
      } finally {
        setIsAddingCategory(false);
      }
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      try {
        await axios.delete(`https://api.payasvinimilk.com/api/categories/deleteCategory/${categoryId}`);
        setCategories(categories.filter((category) => category._id !== categoryId));
        setSubCategories((prev) => {
          const updated = { ...prev };
          delete updated[categoryId];
          return updated;
        });
        alert('Category deleted successfully!');
      } catch (error) {
        console.error('Error deleting category:', error);
      }
    }
  };

  const handleAddSubCategory = async (e) => {
    e.preventDefault();
    if (newSubCategoryName.trim() && newSubCategoryImage && selectedCategoryId) {
      setIsAddingSubCategory(true);
      const formData = new FormData();
      formData.append('name', newSubCategoryName.trim());
      formData.append('imgPath', newSubCategoryImage);

      try {
        const response = await axios.post(
          `https://api.payasvinimilk.com/api/categories/addSubCategory/${selectedCategoryId}`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        setSubCategories({
          ...subCategories,
          [selectedCategoryId]: [...(subCategories[selectedCategoryId] || []), response.data],
        });
        setNewSubCategoryName('');
        setNewSubCategoryImage(null);
        alert(`Subcategory "${newSubCategoryName}" added successfully for category!`);
        window.location.reload();
      } catch (error) {
        console.error('Error adding subcategory:', error);
      } finally {
        setIsAddingSubCategory(false);
      }
    }
  };

  const handleDeleteSubCategory = async (categoryId, subCategoryId) => {
    if (window.confirm('Are you sure you want to delete this subcategory?')) {
      try {
        await axios.delete(`https://api.payasvinimilk.com/api/categories/deleteSubCategory/${categoryId}/${subCategoryId}`);
        setSubCategories({
          ...subCategories,
          [categoryId]: subCategories[categoryId].filter((subCat) => subCat._id !== subCategoryId),
        });
        alert('Subcategory deleted successfully!');
      } catch (error) {
        console.error('Error deleting subcategory:', error);
      }
    }
  };

  const handleEditCategory = async (categoryId) => {
    if (editCategoryName.trim()) {
      const formData = new FormData();
      formData.append('name', editCategoryName.trim());
      if (editCategoryImage) formData.append('image', editCategoryImage);

      try {
        const response = await axios.put(`https://api.payasvinimilk.com/api/categories/updateCategory/${categoryId}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        setCategories(
          categories.map((category) =>
            category._id === categoryId ? { ...category, name: response.data.name, image: response.data.image } : category
          )
        );
        setEditCategoryId(null);
        setEditCategoryName('');
        setEditCategoryImage(null);
        alert('Category updated successfully!');
      } catch (error) {
        console.error('Error updating category:', error);
      }
    }
  };
  
  const handleEditSubCategory = async (categoryId) => {
    if (editSubCategoryName.trim()) {
      const formData = new FormData();
      formData.append('name', editSubCategoryName.trim());
      if (editSubCategoryImage) formData.append('imgPath', editSubCategoryImage);

      try {
        const response = await axios.put(
          `https://api.payasvinimilk.com/api/categories/updateSubcategory/${categoryId}/${editSubCategoryId}`,
          formData,
          { headers: { 'Content-Type': 'multipart/form-data' } }
        );
        setSubCategories((prev) => ({
          ...prev,
          [categoryId]: prev[categoryId].map((subCat) => 
            subCat._id === editSubCategoryId ? response.data : subCat
          ),
        }));
        setEditSubCategoryId(null);
        setEditSubCategoryName('');
        setEditSubCategoryImage(null);
        alert('Subcategory updated successfully!');
      } catch (error) {
        console.error('Error updating subcategory:', error);
      }
    }
  };


  const handleImageChange = (e) => setNewCategoryImage(e.target.files[0]);
  const handleSubCategoryImageChange = (e) => setNewSubCategoryImage(e.target.files[0]);

  return (
    <div className="max-w-7xl mx-auto p-8 border border-gray-300 rounded-lg bg-white shadow-lg">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">Categories and Subcategories</h1>

      {/* Add Category Form */}
      <form onSubmit={handleAddCategory} className="mb-10 p-6 bg-gray-100 rounded-lg shadow-sm">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Add a New Category</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 mb-2">Category Name</label>
            <input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Enter category name"
              className="border border-gray-300 p-3 rounded-lg w-full text-gray-900 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-2">Category Image</label>
            <input
              type="file"
              onChange={handleImageChange}
              className="border border-gray-300 p-3 rounded-lg w-full"
            />
          </div>
        </div>
        <button
          type="submit"
          className="mt-4 bg-blue-600 text-white px-5 py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          {isAddingCategory ? 'Adding...' : 'Add Category'}
        </button>
      </form>      

      {/* Add Subcategory Form */}
      <form onSubmit={handleAddSubCategory} className="mt-8 p-6 bg-gray-100 rounded-lg shadow-sm mb-8">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Add a New Subcategory</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-600 mb-2">Category</label>
            <select
              value={selectedCategoryId}
              onChange={(e) => setSelectedCategoryId(e.target.value)}
              className="border border-gray-300 p-3 rounded-lg w-full text-gray-900 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-600 mb-2">Subcategory Name</label>
            <input
              type="text"
              value={newSubCategoryName}
              onChange={(e) => setNewSubCategoryName(e.target.value)}
              placeholder="Enter subcategory name"
              className="border border-gray-300 p-3 rounded-lg w-full text-gray-900 focus:ring-2 focus:ring-blue-500 focus:outline-none"
            />
          </div>
          <div>
            <label className="block text-gray-600 mb-2">Subcategory Image</label>
            <input
              type="file"
              onChange={handleSubCategoryImageChange}
              className="border border-gray-300 p-3 rounded-lg w-full"
            />
          </div>
        </div>
        <button
          type="submit"
          className="mt-4 bg-blue-600 text-white px-5 py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          {isAddingSubCategory ? 'Adding...' : 'Add Subcategory'}
        </button>
      </form>

      {/* Existing Categories List */}
      <div>
        {categories.length > 0 ? (
          categories.map((category) => (
            <div key={category._id} className="mb-8 p-6 bg-gray-100 rounded-lg shadow-sm">
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <img
                    src={category.image}
                    alt={category.name}
                    className="w-16 h-16 object-cover rounded-lg"
                  />
                  {editCategoryId === category._id ? (
                    <>
                      <input
                        type="text"
                        value={editCategoryName}
                        onChange={(e) => setEditCategoryName(e.target.value)}
                        className="border border-gray-300 p-2 rounded-lg"
                        placeholder="Edit category name"
                      />
                      <input type="file" onChange={(e) => setEditCategoryImage(e.target.files[0])} />
                      <button
                        onClick={() => handleEditCategory(category._id)}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        Save
                      </button>
                      <button onClick={() => setEditCategoryId(null)} className="text-red-600 hover:text-red-800">
                        Cancel
                      </button>
                    </>
                  ) : (
                    <h3 className="text-xl font-semibold text-gray-900">{category.name}</h3>
                  )}
                </div>
                <div className="flex space-x-4">
                  <button
                    onClick={() => setEditCategoryId(category._id)}
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteCategory(category._id)}
                    className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700"
                  >
                    Delete
                  </button>
                </div>
              </div>

              {/* Subcategories List */}
              <div className="mt-4">
                <h4 className="text-lg font-semibold text-gray-700 mb-2">Subcategories</h4>
                <ul className="space-y-2">
                  {subCategories[category._id]?.map((subCategory) => (
                    <li key={subCategory._id} className="flex justify-between items-center">
                      <div className="flex items-center space-x-4">
                        <img
                          src={subCategory.imgPath}
                          alt={subCategory.name}
                          className="w-12 h-12 object-cover rounded-lg"
                        />
                        {editSubCategoryId === subCategory._id ? (
                          <>
                            <input
                              type="text"
                              value={editSubCategoryName}
                              onChange={(e) => setEditSubCategoryName(e.target.value)}
                              className="border border-gray-300 p-2 rounded-lg"
                              placeholder="Edit subcategory name"
                            />
                            <input
                              type="file"
                              onChange={(e) => setEditSubCategoryImage(e.target.files[0])}
                            />
                            <button
                              onClick={() => handleEditSubCategory(category._id)}
                              className="text-blue-600 hover:text-blue-800 hover:underline"
                            >
                              Save
                            </button>
                            <button onClick={() => setEditSubCategoryId(null)} className="text-red-600 hover:text-red-800 hover:underline">
                              Cancel
                            </button>
                          </>
                        ) : (
                          <span>{subCategory.name}</span>
                        )}
                      </div>
                      <div className="flex space-x-4">
                        <button
                          onClick={() => setEditSubCategoryId(subCategory._id) || setEditSubCategoryName(subCategory.name)}
                          className="text-blue-600 hover:text-blue-800 hover:underline"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDeleteSubCategory(category._id, subCategory._id)}
                          className="text-red-600 hover:text-red-800 hover:underline"
                        >
                          Delete
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
                
              </div>
            </div>
          ))
        ) : (
          <p>No categories found.</p>
        )}
      </div>
    </div>
  );
};

export default Categories;
