import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CurrencyDollarIcon, UserIcon, CubeIcon, ShoppingCartIcon } from '@heroicons/react/solid';

const BusinessDashboard = () => {
  // Dynamic data
  const [wallet, setWallet] = useState(1000);
  const [users, setUsers] = useState(120);
  const [products, setProducts] = useState(50);
  const [orders, setOrders] = useState(75);

  return (
    <div className=" bg-gray-100 flex flex-col items-center pt-16 ">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8 w-full max-w-6xl">

        {/* Wallet Card */}
        <Link to="/businessWallet">
          <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col items-center text-center transform transition duration-300 hover:scale-105">
            <CurrencyDollarIcon className="h-16 w-16 text-green-500 mb-4" />
            <h2 className="text-2xl font-semibold mb-2">Wallet</h2>
            <p className="text-3xl font-bold text-green-600">₹{wallet}</p>
          </div>
        </Link>

        {/* Users Card */}
        <Link to="/businessUsers">
          <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col items-center text-center transform transition duration-300 hover:scale-105">
            <UserIcon className="h-16 w-16 text-blue-500 mb-4" />
            <h2 className="text-2xl font-semibold mb-2">Users</h2>
            <p className="text-3xl font-bold text-blue-600">{users}</p>
          </div>
        </Link>

        {/* Products Card */}
        <Link to="/businessproducts">
          <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col items-center text-center transform transition duration-300 hover:scale-105">
            <CubeIcon className="h-16 w-16 text-purple-500 mb-4" />
            <h2 className="text-2xl font-semibold mb-2">Products</h2>
            <p className="text-3xl font-bold text-purple-600">{products}</p>
          </div>
        </Link>

        {/* Orders Card */}
        <Link to="/businessOrders">
          <div className="bg-white shadow-lg rounded-lg p-8 flex flex-col items-center text-center transform transition duration-300 hover:scale-105">
            <ShoppingCartIcon className="h-16 w-16 text-red-500 mb-4" />
            <h2 className="text-2xl font-semibold mb-2">Orders</h2>
            <p className="text-3xl font-bold text-red-600">{orders}</p>
          </div>
        </Link>

      </div>
    </div>
  );
};

export default BusinessDashboard;
