import React, { useState, useEffect } from 'react';
import axios from 'axios';

const BusinessProducts = () => {
  // State variables for products and form data
  const [businessProducts, setBusinessProducts] = useState([]);
  const [businessProductName, setBusinessProductName] = useState('');
  const [businessProductPrice, setBusinessProductPrice] = useState({});
  const [businessProductShippingTime, setBusinessProductShippingTime] = useState('');
  const [businessProductImage, setBusinessProductImage] = useState(null);
  const [minimumQuantity, setMinimumQuantity] = useState(1); // New state for minimum quantity
  const [isDieShape, setIsDieShape] = useState(false); // New state for die shape checkbox
  const [userTypes, setUserTypes] = useState([]);
  const [businessIsEditing, setBusinessIsEditing] = useState(false);
  const [businessEditProductId, setBusinessEditProductId] = useState(null);

  // Fetch all user types from the API
  const fetchUserTypes = async () => {
    try {
      const response = await axios.get('https://api.payasvinimilk.com/api/userType/getUserTypes');
      setUserTypes(response.data);
    } catch (error) {
      console.error('Error fetching user types:', error);
    }
  };

  // Fetch all products from the API
  const fetchBusinessProducts = async () => {
    try {
      const response = await axios.get('https://api.payasvinimilk.com/api/businessProducts/getAllBusinessProducts');
      setBusinessProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  // Fetch user types and products when the component mounts
  useEffect(() => {
    fetchUserTypes();
    fetchBusinessProducts();
  }, []);

  // Function to handle price input for each user type
  const handlePriceChange = (userTypeId, value) => {
    setBusinessProductPrice((prev) => ({
      ...prev,
      [userTypeId]: value,
    }));
  };

  // Function to handle adding new products
  const addBusinessProduct = async () => {
    if (businessProductName && Object.keys(businessProductPrice).length > 0 && businessProductShippingTime && businessProductImage) {
      const formData = new FormData();
      formData.append('name', businessProductName);
      formData.append('prices', JSON.stringify(businessProductPrice)); // Convert prices to a JSON string
      formData.append('shippingTime', businessProductShippingTime);
      formData.append('image', businessProductImage);
      formData.append('minimumQuantity', minimumQuantity); // Add minimum quantity
      formData.append('isDieShape', isDieShape); // Add die shape checkbox value

      try {
        const response = await axios.post('https://api.payasvinimilk.com/api/businessProducts/createBusinessProduct', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setBusinessProducts([...businessProducts, response.data]);
        alert('Product added successfully!'); // Alert for addition
        resetForm();
      } catch (error) {
        console.error('Error adding product:', error);
      }
    } else {
      alert('Please fill all fields.');
    }
  };

  // Function to handle editing products
  const editBusinessProduct = (productId) => {
    const product = businessProducts.find((p) => p._id === productId);
    setBusinessProductName(product.name);
    setBusinessProductPrice(product.prices);
    setBusinessProductShippingTime(product.shippingTime);
    setBusinessProductImage(product.image); // Keep this as is, but you might want to change how you handle it
    setMinimumQuantity(product.minimumQuantity || 1); // Set minimum quantity
    setIsDieShape(product.isDieShape || false); // Set die shape checkbox
    setBusinessIsEditing(true);
    setBusinessEditProductId(product._id);
  };

  // Function to handle updating products
  const updateBusinessProduct = async () => {
    if (window.confirm('Are you sure you want to update this product?')) {
      const formData = new FormData();
      formData.append('name', businessProductName);
      formData.append('prices', JSON.stringify(businessProductPrice)); // Convert prices to a JSON string
      formData.append('shippingTime', businessProductShippingTime);
      formData.append('image', businessProductImage);
      formData.append('minimumQuantity', minimumQuantity); // Update minimum quantity
      formData.append('isDieShape', isDieShape); // Update die shape checkbox value

      try {
        const response = await axios.put(`https://api.payasvinimilk.com/api/businessProducts/updateBusinessProduct/${businessEditProductId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const updatedProducts = businessProducts.map((product) =>
          product._id === businessEditProductId ? response.data : product
        );

        setBusinessProducts(updatedProducts);
        alert('Product updated successfully!'); // Alert for update
        resetForm();
      } catch (error) {
        console.error('Error updating product:', error);
      }
    }
  };

  // Function to handle deleting products
  const deleteBusinessProduct = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await axios.delete(`https://api.payasvinimilk.com/api/businessProducts/deleteBusinessProduct/${productId}`);
        const filteredProducts = businessProducts.filter((p) => p._id !== productId);
        setBusinessProducts(filteredProducts);
        alert('Product deleted successfully!'); // Alert for deletion
      } catch (error) {
        console.error('Error deleting product:', error);
      }
    }
  };

  const resetForm = () => {
    setBusinessProductName('');
    setBusinessProductPrice({});
    setBusinessProductShippingTime('');
    setBusinessProductImage(null);
    setMinimumQuantity(0); // Reset minimum quantity
    setIsDieShape(false); // Reset die shape checkbox
    setBusinessIsEditing(false);
    setBusinessEditProductId(null);
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      {/* Product Form */}
      <div className="bg-white shadow-md rounded-lg p-6 mb-8">
        <h2 className="text-2xl mb-4">{businessIsEditing ? 'Edit Product' : 'Add New Product'}</h2>
        <div className="mb-4">
          <input
            type="text"
            placeholder="Product Name"
            className="w-full p-2 border rounded-lg mb-2"
            value={businessProductName}
            onChange={(e) => setBusinessProductName(e.target.value)}
          />
          {userTypes.map((userType) => (
            <div key={userType._id} className="mb-2">
              <label className="block">{userType.type} Price</label>
              <input
                type="number"
                placeholder={`Price for ${userType.type}`}
                className="w-full p-2 border rounded-lg"
                value={businessProductPrice[userType._id] || ''}
                onChange={(e) => handlePriceChange(userType._id, e.target.value)}
              />
            </div>
          ))}
          <input
            type="text"
            placeholder="Shipping Time"
            className="w-full p-2 border rounded-lg mb-2"
            value={businessProductShippingTime}
            onChange={(e) => setBusinessProductShippingTime(e.target.value)}
          />
          <input
            type="file"
            accept="image/*"
            className="w-full p-2 border rounded-lg mb-2"
            onChange={(e) => setBusinessProductImage(e.target.files[0])}
          />
          <div className="mb-2">
            <label className="block">Minimum Quantity</label>
            <input
              type="number"
              placeholder="Minimum Quantity"
              className="w-full p-2 border rounded-lg"
              value={minimumQuantity}
              onChange={(e) => setMinimumQuantity(e.target.value)}
            />
          </div>
          <div className="flex items-center mb-2">
            <input
              type="checkbox"
              checked={isDieShape}
              onChange={(e) => setIsDieShape(e.target.checked)}
              className="mr-2"
            />
            <label>Is Die Shape</label>
          </div>
        </div>
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          onClick={businessIsEditing ? updateBusinessProduct : addBusinessProduct}
        >
          {businessIsEditing ? 'Update Product' : 'Add Product'}
        </button>
        {businessIsEditing && (
            <button
              className="bg-gray-500 text-white px-4 py-2 ml-5 rounded-lg"
              onClick={resetForm}
            >
              Cancel
            </button>
          )}
      </div>

      {/* Product List */}
      <div className="bg-white shadow-md rounded-lg p-6">
  <h2 className="text-2xl mb-4">Product List</h2>
  <ul>
    {businessProducts.map((product) => (
      <li
        key={product._id}
        className="flex justify-between items-center mb-4 p-4 bg-gray-50 rounded-lg"
      >
        <div className="flex items-center">
          <img src={product.image} alt={product.name} className="w-16 h-16 object-cover rounded-lg mr-4" />
          <div>
            <p className="font-bold">{product.name}</p>
            {Object.entries(product.prices).map(([userTypeId, price]) => (
              <p key={userTypeId}>
                {userTypes.find((type) => type._id === userTypeId)?.type}: ${price}
              </p>
            ))}
            <p>Shipping Time: {product.shippingTime}</p>
            <p>Minimum Quantity: {product.minimumQuantity}</p> {/* Display Minimum Quantity */}
            <p>Is Die Shape: {product.isDieShape ? 'Yes' : 'No'}</p> {/* Display Is Die Shape */}
          </div>
        </div>
        <div>
          <button
            className="bg-green-500 text-white px-4 py-2 mr-2 rounded-lg"
            onClick={() => editBusinessProduct(product._id)}
          >
            Edit
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
            onClick={() => deleteBusinessProduct(product._id)}
          >
            Delete
          </button>
        </div>
      </li>
    ))}
  </ul>
</div>

    </div>
  );
};

export default BusinessProducts;
