import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function ViewProduct() {
  const { id } = useParams(); // Get product ID from URL params
  const [product, setProduct] = useState(null);

  useEffect(() => {
    // Fetch product by ID
    axios.get(`https://api.payasvinimilk.com/api/products/getProductById/${id}`)
      .then(response => {
        setProduct(response.data);
      })
      .catch(error => {
        console.error('Error fetching product:', error);
      });
  }, [id]);

  if (!product) return <p className="text-center text-lg">Loading product details...</p>;

  return (
    <div className="container mx-auto p-6">
      <div className="bg-white shadow-lg rounded-lg flex flex-col md:flex-row overflow-hidden">
        {/* Image Gallery */}
        <div className="md:w-1/2">
          <div className="flex justify-center">
            <img
              src={product.images[0]} // Display the first image prominently
              alt="Main Product"
              className="h-96 w-auto object-cover rounded-t-lg"
            />
          </div>
          <div className="flex overflow-x-auto space-x-2 mt-2">
            {product.images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Product Image ${index + 1}`}
                className="h-24 w-24 object-cover rounded-lg shadow-md cursor-pointer transition-transform transform hover:scale-105"
              />
            ))}
          </div>
        </div>
        {/* Product Details */}
        <div className="md:w-1/2 px-6 mb-4 flex flex-col justify-between">
          <h2 className="text-3xl font-bold mb-4">{product.name}</h2>
          <div className="space-y-2">
            <p><strong>Product ID:</strong> {product._id}</p>
            <p><strong>Category:</strong> {product?.category?.name || "Generic"}</p>
            <p><strong>Subcategory:</strong> {product.subCategory || "N/A"}</p>
            <p><strong>Description:</strong> {product.description}</p>
            <p>
              <strong>Price Tiers:</strong> 
              {product.priceTiers.map((tier, idx) => (
                <span key={idx} className="block">Quantity: {tier.quantity}, Price: ${tier.price}</span>
              ))}
            </p>
            <p><strong>Die Shape:</strong> {product.isDieShape ? 'Yes' : 'No'}</p>
            <p><strong>Paper Type Available:</strong> {product.isPaperType ? 'Yes' : 'No'}</p>
            {product.isPaperType && product.paperTypes.length > 0 && (
              <div>
                <strong>Paper Types:</strong>
                <ul className="list-disc ml-5">
                  {product.paperTypes.map((type, index) => (
                    <li key={index}>{type}</li>
                  ))}
                </ul>
              </div>
            )}
            <p><strong>Flap Opening Available:</strong> {product.isFlapOpening ? 'Yes' : 'No'}</p>
            {product.isFlapOpening && product.flapOpenings.length > 0 && (
              <div>
                <strong>Flap Openings:</strong>
                <ul className="list-disc ml-5">
                  {product.flapOpenings.map((type, index) => (
                    <li key={index}>{type}</li>
                  ))}
                </ul>
              </div>
            )}
            <p><strong>Window Cutting Available:</strong> {product.isWindowCutting ? 'Yes' : 'No'}</p>
            {product.isWindowCutting && product.windowCuttings.length > 0 && (
              <div>
                <strong>Window Cuttings:</strong>
                <ul className="list-disc ml-5">
                  {product.windowCuttings.map((type, index) => (
                    <li key={index}>{type}</li>
                  ))}
                </ul>
              </div>
            )}
            <p><strong>Minimum Quantity:</strong> {product.minimumQuantity}</p>
            <p><strong>Expected Delivery Time:</strong> {product.expectedDeliveryTime}</p>
            <p>
              <strong>Video URL:</strong> 
              <a 
                href={product.videoUrl} 
                className="text-blue-500 hover:underline" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                {product.videoUrl}
              </a>
            </p>
            <p><strong>Date Added:</strong> {new Date(product.createdAt).toLocaleDateString()}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewProduct;
