import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import Addproduct from './pages/Addproduct';
import Categories from './pages/Categories';
import Login from './pages/Login';
import Orders from './pages/Orders';
import Updateproduct from './pages/Updateproduct';
import Users from './pages/Users';
import Dashboard from './pages/Dashboard';
import OrderDetail from './pages/OrderDetail';
import BusinessDashboard from './pages/BusinessDashboard';
import BusinessProducts from './pages/BusinessProducts';
import BusinessWallet from './pages/BusinessWallet';
import BusinessUsers from './pages/BusinessUsers';
import BusinessOrders from './pages/BusinessOrders';
import Navbar from './components/Navbar';
import DieShapes from './pages/DieShapes';
import BusinessOrderDetails from './pages/BusinessOrderDetails';
import ViewProduct from './pages/ViewProduct';
import AddBanner from './pages/AddBanner';

const Layout = () => {
  const location = useLocation();

  // Determine if we are on the login page
  const isLoginPage = location.pathname === '/login';

  return (
    <div className="flex flex-col h-screen">
      {!isLoginPage && <Navbar/>}
      <div className="flex flex-1 overflow-hidden">
        {!isLoginPage && <Sidebar className="w-64" />}
        <main className="flex-1 overflow-auto">
          <Routes>
            <Route path="/addproduct" element={<Addproduct />} />
            <Route path="/banner" element={<AddBanner />} />
            <Route path="/product/:id" element={<ViewProduct />} />
            <Route path="/categories" element={<Categories />} />
            <Route path="/login" element={<Login />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/updateproduct" element={<Updateproduct />} />
            <Route path="/users" element={<Users />} />
            <Route path="/order/:orderId" element={<OrderDetail />} />
            <Route path="/dieShapes" element={<DieShapes/>}/>
            <Route path="/" element={<Dashboard />} />
            {/* business links */}
            <Route path="/businessDash" element={<BusinessDashboard/>}/>
            <Route path="/businessproducts" element={<BusinessProducts/>}/>
            <Route path="/businessWallet" element={<BusinessWallet/>}/>
            <Route path="/businessUsers" element={<BusinessUsers/>}/>
            <Route path="/businessOrders" element={<BusinessOrders/>}/>
            <Route path="/businessOrders-details/:id" element={<BusinessOrderDetails />} />
         
            {/* Handle 404 Not Found */}
            <Route path="*" element={<div>404 Not Found</div>} />
          </Routes>
        </main>
      </div>
      {!isLoginPage && <Footer />}
    </div>
  );
};



export default Layout;
