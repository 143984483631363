import React from 'react';

const Login = () => {
  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Left Section */}
      <div className="w-full md:w-1/2 flex flex-col justify-center items-start bg-gray-800 text-white p-8">
        <h1 className="text-4xl font-bold mb-4 text-left">Welcome to Sasta Print</h1>
        <p className="text-lg mb-6 text-left">Made with love by Soft Coders</p>
        <p className="text-sm text-left">We're excited to have you here! Please log in to continue.</p>
      </div>

      {/* Right Section */}
      <div className="w-full md:w-1/2 flex items-center justify-center p-8">
        <div className="w-full max-w-sm bg-white p-8 rounded-none shadow-lg">
          <h2 className="text-2xl font-bold mb-6 text-gray-900 text-left">Login</h2>
          <form>
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 text-left">Email</label>
              <input
                type="email"
                id="email"
                className="border border-gray-300 p-3 rounded-none w-full focus:ring-2 focus:ring-gray-700"
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block text-gray-700 text-left">Password</label>
              <input
                type="password"
                id="password"
                className="border border-gray-300 p-3 rounded-none w-full focus:ring-2 focus:ring-gray-700"
                placeholder="Enter your password"
              />
            </div>
            <button
              type="submit"
              className="bg-gray-700 text-white px-5 py-3 rounded-none hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-700"
            >
              Log In
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
