import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function AddProduct() {
  const [product, setProduct] = useState({
    name: "",
    price: "",
    description: "",
    category: "",
    subCategory: "",
    expectedDeliveryTime: "",
    minQuantity: 1,
    isDieShape: false,
    isPaperType: false,
    isFlapOpening: false,
    isWindowCutting: false,
    isPrinting: false,
    isLaminationType: false,
    videoUrl: "",
    priceTiers: [{ quantity: 1, price: "" }],
    paperTypes: [""],
    flapOpenings: [""],
    windowCuttings: [""],
    printings: [""],
    laminationTypes: [""],
  });

  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoryNames, setSubCategoryNames] = useState({});
  const [images, setImages] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingProductId, setEditingProductId] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch all products from the backend
    axios
      .get("https://api.payasvinimilk.com/api/products/getProducts")
      .then((response) => {
        setProducts(response.data);
        fetchSubCategoryNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get("https://api.payasvinimilk.com/api/categories/getCategories")
      .then((response) => {
        setCategories(response.data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const fetchSubCategoryNames = async (products) => {
    const subCategoryIds = products.map(product => product.subCategory).filter(Boolean);
    const uniqueSubCategoryIds = [...new Set(subCategoryIds)];

    const names = await Promise.all(uniqueSubCategoryIds.map(async (id) => {
      try {
        const response = await axios.get(`https://api.payasvinimilk.com/api/categories/subcategories/${id}`);
        return { id, name: response.data.name };
      } catch (error) {
        console.error(`Error fetching subcategory name for ID ${id}:`, error);
        return { id, name: "N/A" };
      }
    }));

    const subCategoryNamesMap = names.reduce((acc, { id, name }) => ({ ...acc, [id]: name }), {});
    setSubCategoryNames(subCategoryNamesMap);
  };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setProduct({
      ...product,
      [name]: type === "checkbox" ? checked : value,
    });

    if (name === "category") {
      const selectedCategory = categories.find(
        (category) => category._id === value
      );
      setSubCategories(selectedCategory ? selectedCategory.subcategory : []);
      setProduct((prevProduct) => ({
        ...prevProduct,
        subCategory: "",
      }));
    }
  };

  const handleImageChange = (e) => {
    setImages(Array.from(e.target.files));
  };

  const handlePaperTypeChange = (index, e) => {
    const newPaperTypes = [...product.paperTypes];
    newPaperTypes[index] = e.target.value;
    setProduct({ ...product, paperTypes: newPaperTypes });
  };

  const addPaperTypeField = () => {
    setProduct({ ...product, paperTypes: [...product.paperTypes, ""] });
  };

  const removePaperTypeField = (index) => {
    const newPaperTypes = product.paperTypes.filter((_, i) => i !== index);
    setProduct({ ...product, paperTypes: newPaperTypes });
  };

  const handleFlapOpeningChange = (index, e) => {
    const newFlapOpenings = [...product.flapOpenings];
    newFlapOpenings[index] = e.target.value;
    setProduct({ ...product, flapOpenings: newFlapOpenings });
  };

  const addFlapOpeningField = () => {
    setProduct({ ...product, flapOpenings: [...product.flapOpenings, ""] });
  };

  const removeFlapOpeningField = (index) => {
    const newFlapOpenings = product.flapOpenings.filter((_, i) => i !== index);
    setProduct({ ...product, flapOpenings: newFlapOpenings });
  };

  const handleWindowCuttingChange = (index, e) => {
    const newWindowCuttings = [...product.windowCuttings];
    newWindowCuttings[index] = e.target.value;
    setProduct({ ...product, windowCuttings: newWindowCuttings });
  };

  const addWindowCuttingField = () => {
    setProduct({ ...product, windowCuttings: [...product.windowCuttings, ""] });
  };

  const removeWindowCuttingField = (index) => {
    const newWindowCuttings = product.windowCuttings.filter((_, i) => i !== index);
    setProduct({ ...product, windowCuttings: newWindowCuttings });
  };

  const handlePrintingChange = (index, e) => {
    const newPrintings = [...product.printings];
    newPrintings[index] = e.target.value;
    setProduct({ ...product, printings: newPrintings });
  };

  const addPrintingField = () => {
    setProduct({ ...product, printings: [...product.printings, ""] });
  };

  const removePrintingField = (index) => {
    const newPrintings = product.printings.filter((_, i) => i !== index);
    setProduct({ ...product, printings: newPrintings });
  };

  const handleLaminationTypeChange = (index, e) => {
    const newLaminationTypes = [...product.laminationTypes];
    newLaminationTypes[index] = e.target.value;
    setProduct({ ...product, laminationTypes: newLaminationTypes });
  };

  const addLaminationTypeField = () => {
    setProduct({ ...product, laminationTypes: [...product.laminationTypes, ""] });
  };

  const removeLaminationTypeField = (index) => {
    const newLaminationTypes = product.laminationTypes.filter((_, i) => i !== index);
    setProduct({ ...product, laminationTypes: newLaminationTypes });
  };

  const handlePriceTierChange = (index, e) => {
    const { name, value } = e.target;
    const newPriceTiers = [...product.priceTiers];
    newPriceTiers[index][name] = value;
    setProduct({ ...product, priceTiers: newPriceTiers });
  };

  const addPriceTier = () => {
    setProduct({
      ...product,
      priceTiers: [...product.priceTiers, { quantity: 1, price: "" }],
    });
  };

  const removePriceTier = (index) => {
    const newPriceTiers = product.priceTiers.filter((_, i) => i !== index);
    setProduct({ ...product, priceTiers: newPriceTiers });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation to check if required fields are filled
    if (!product.name) {
      alert("Please fill in the Name field.");
      return; // Stop the function execution if Name is missing
    }

    if (!product.description) {
      alert("Please fill in the Description field.");
      return; // Stop the function execution if Description is missing
    }

    if (!product.expectedDeliveryTime) {
      alert("Please fill in the Expected Delivery Time field.");
      return; // Stop the function execution if Expected Delivery Time is missing
    }
    setLoading(true);

    const formData = new FormData();
    formData.append("name", product.name);
    formData.append("description", product.description);
    formData.append("category", product.category);
    formData.append("subCategory", product.subCategory);
    formData.append("expectedDeliveryTime", product.expectedDeliveryTime);
    formData.append("minimumQuantity", product.minQuantity);
    formData.append("isDieShape", product.isDieShape);
    formData.append("isPaperType", product.isPaperType);
    formData.append("isWindowCutting", product.isWindowCutting);
    formData.append("isFlapOpening", product.isFlapOpening);
    formData.append("isPrinting", product.isPrinting);
    formData.append("isLaminationType", product.isLaminationType);
    formData.append("priceTiers", JSON.stringify(product.priceTiers));
    formData.append("paperTypes", JSON.stringify(product.paperTypes));
    formData.append("windowCuttings", JSON.stringify(product.windowCuttings));
    formData.append("flapOpenings", JSON.stringify(product.flapOpenings));
    formData.append("printings", JSON.stringify(product.printings));
    formData.append("laminationTypes", JSON.stringify(product.laminationTypes));
    formData.append("videoUrl", product.videoUrl);
    images.forEach((image) => {
      formData.append("images", image);
    });

    try {
      if (isEditing) {
        // Update existing product
        await axios.put(
          `https://api.payasvinimilk.com/api/products/updateProduct/${editingProductId}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        alert("Product updated successfully");
        setIsEditing(false);
        setEditingProductId(null);
      } else {
        // Add new product
        await axios.post(
          "https://api.payasvinimilk.com/api/products/createProduct",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        alert("Product added successfully");
      }
      setProduct({
        name: "",
        description: "",
        category: "",
        subCategory: "",
        expectedDeliveryTime: "",
        minQuantity: 1,
        isDieShape: false,
        isPaperType: false,
        isWindowCutting: false,
        isFlapOpening: false,
        isPrinting: false,
        isLaminationType: false,
        videoUrl: "",
        paperTypes: [""],
        windowCuttings: [""],
        flapOpenings: [""],
        printings: [""],
        laminationTypes: [""],
        priceTiers: [{ quantity: "", price: "" }],
      });
      setImages([]);

      // Fetch updated product list
      const response = await axios.get(
        "https://api.payasvinimilk.com/api/products/getProducts"
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error adding product:", error);
    } finally {
      setLoading(false); // End loading
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this Product?")) {
      try {
        await axios.delete(
          `https://api.payasvinimilk.com/api/products/deleteProduct/${id}`
        );
        alert("Product deleted successfully");
        setProducts(products.filter((product) => product._id !== id));
      } catch (error) {
        console.error("Error deleting product:", error);
      }
    }
  };
  async function getSubCategoryNameById(subCategoryId) {
    if (!subCategoryId) {
      return "N/A"; // Return "N/A" if no ID is provided
    }

    try {
      const response = await fetch(`your-api-url/subcategories/${subCategoryId}`);

      // Check if the response is ok (status in the range 200-299)
      if (!response.ok) {
        throw new Error('Failed to fetch subcategory name');
      }

      const data = await response.json();
      return data.name; // Assuming the API returns { name: 'SubCategory Name' }
    } catch (error) {
      console.error('Error fetching subcategory name:', error);
      return "N/A"; // Return "N/A" on error
    }
  }

  const handleEdit = (product) => {
    // Scroll to the top of the window
    window.scrollTo(0, 0);

    // Set the editing state
    setIsEditing(true);

    // Update the editing product ID
    setEditingProductId(product._id);

    // Set the product details in state
    setProduct({
      name: product.name,
      price: product.price,
      description: product.description,
      category: product.category ? product.category._id : null,
      subCategory: product.subCategory,
      expectedDeliveryTime: product.expectedDeliveryTime,
      minQuantity: product.minimumQuantity,
      isDieShape: product.isDieShape,
      isPaperType: product.isPaperType,
      isWindowCutting: product.isWindowCutting,
      isFlapOpening: product.isFlapOpening,
      isPrinting: product.isPrinting,
      isLaminationType: product.isLaminationType,
      paperTypes: product.paperTypes,
      windowCuttings: product.windowCuttings,
      flapOpenings: product.flapOpenings,
      printings: product.printings,
      laminationTypes: product.laminationTypes,
      videoUrl: product.videoUrl,
      priceTiers: product.priceTiers,
    });

    // Clear the images array
    setImages([]);
  };

  const handleAdd = (product) => {
    // Scroll to the top of the window
    window.scrollTo(0, 0);

    // Set the product details in state
    setProduct({
      name: product.name,
      price: product.price,
      description: product.description,
      category: product.category ? product.category._id : null,
      subCategory: product.subCategory,
      expectedDeliveryTime: product.expectedDeliveryTime,
      minQuantity: product.minimumQuantity,
      isDieShape: product.isDieShape,
      isPaperType: product.isPaperType,
      isWindowCutting: product.isWindowCutting,
      isFlapOpening: product.isFlapOpening,
      isPrinting: product.isPrinting,
      isLaminationType: product.isLaminationType,
      paperTypes: product.paperTypes,
      windowCuttings: product.windowCuttings,
      flapOpenings: product.flapOpenings,
      printings: product.printings,
      laminationTypes: product.laminationTypes,
      videoUrl: product.videoUrl,
      priceTiers: product.priceTiers,
    });

    // Clear the images array
    setImages([]);
  };

  const filteredProducts = products
    .filter((product) => {
      const nameMatch = product.name
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const categoryMatch = product?.category?.name
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
      const subcategoryMatch = product?.subcategory
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase());
      return nameMatch || categoryMatch || subcategoryMatch;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const clearForm = () => {
    setProduct({
      name: "",
      price: "",
      description: "",
      category: "",
      subCategory: "",
      expectedDeliveryTime: "",
      minQuantity: 1,
      isDieShape: false,
      isPaperType: false,
      isWindowCutting: false,
      isFlapOpening: false,
      isPrinting: false,
      isLaminationType: false,
      paperTypes: [""],
      windowCuttings: [""],
      flapOpenings: [""],
      printings: [""],
      laminationTypes: [""],
      priceTiers: [{ quantity: 1, price: "" }],
      videoUrl: "",
    });
    setImages([]);
    setIsEditing(false);
  };

  // Navigate to view page
  const handleView = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div>
      <div className=" max-w-full flex justify-center items-center p-4">
        <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-full">
          <h2 className="text-2xl font-bold text-gray-800 mb-6">
            {isEditing ? "Edit Product" : "Add Product"}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-1">
            {/* Category */}
            <div>
              {/* <label className="block text-gray-700 mb-2" htmlFor="category">Category</label> */}
              <select
                id="category"
                name="category"
                value={product.category}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
              >
                <option value="">Select category (Optional)</option>
                {categories.map((category) => (
                  <option key={category._id} value={category._id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            {/* Subcategory */}
            <div>
              <select
                id="subCategory"
                name="subCategory"
                value={product.subCategory}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                disabled={!subCategories.length}
              >
                <option value="">Select subcategory (Optional)</option>
                {subCategories.map((subCategory) => (
                  <option key={subCategory._id} value={subCategory._id}>
                    {subCategory.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              {/* <label className="block text-gray-700 mb-2" htmlFor="name">Product Name</label> */}
              <input
                type="text"
                id="name"
                name="name"
                value={product.name}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                placeholder="Enter product name"
              />
            </div>
            {/* Description */}
            <div>
              <textarea
                id="description"
                name="description"
                value={product.description}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                placeholder="Enter product description"
              />
            </div>

            {/* Expected Delivery Time */}
            <div className="flex items-center space-x-4">
              {/* Expected Delivery Time */}
              <div className="flex-1">
                <input
                  type="text"
                  id="expectedDeliveryTime"
                  name="expectedDeliveryTime"
                  value={product.expectedDeliveryTime}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                  placeholder="Enter expected delivery time"
                />
              </div>

              {/* Minimum Quantity */}
              <div className="flex-1">
                <input
                  type="number"
                  id="minQuantity"
                  name="minQuantity"
                  value={product.minQuantity}
                  onChange={handleChange}
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                  placeholder="Enter minimum quantity"
                />
              </div>

              {/* Is Die Shape? */}
              <div className="flex items-center">
                <label className="mr-2" htmlFor="isDieShape">
                  Is Die Shape?
                </label>
                <input
                  type="checkbox"
                  id="isDieShape"
                  name="isDieShape"
                  checked={product.isDieShape}
                  onChange={handleChange}
                  className="mr-2 leading-tight"
                />
              </div>

              {/* Product Images */}
              <div className="flex-1">
                <input
                  type="file"
                  name="images"
                  multiple
                  onChange={handleImageChange}
                  className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                />
              </div>
            </div>
            <div className="flex items-center">
              <label className="mr-2" htmlFor="isPaperType">
                Is Paper Type?
              </label>
              <input
                type="checkbox"
                name="isPaperType"
                checked={product.isPaperType}
                onChange={handleChange}
                className="mr-2 leading-tight"
              />
            </div>
            {product.isPaperType && (
              <div className="space-y-2">
                {product.paperTypes.map((type, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={type}
                      onChange={(e) => handlePaperTypeChange(index, e)}
                      placeholder="Enter paper type"
                      className="w-full p-2 border rounded-md focus:outline-none"
                    />
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removePaperTypeField(index)}
                        className="bg-red-500 text-white p-2 rounded-md"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addPaperTypeField}
                  className="bg-blue-500 text-white p-2 rounded-md"
                >
                  Add Paper Type
                </button>
              </div>
            )}
            <div className="flex items-center">
              <label className="mr-2" htmlFor="isWindowCutting">
                Is Window Cutting?
              </label>
              <input
                type="checkbox"
                name="isWindowCutting"
                checked={product.isWindowCutting}
                onChange={handleChange}
                className="mr-2 leading-tight"
              />
            </div>
            {product.isWindowCutting && (
              <div className="space-y-2">
                {product.windowCuttings.map((type, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={type}
                      onChange={(e) => handleWindowCuttingChange(index, e)}
                      placeholder="Enter window cutting"
                      className="w-full p-2 border rounded-md focus:outline-none"
                    />
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeWindowCuttingField(index)}
                        className="bg-red-500 text-white p-2 rounded-md"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addWindowCuttingField}
                  className="bg-blue-500 text-white p-2 rounded-md"
                >
                  Add Window Cutting
                </button>
              </div>
            )}
            <div className="flex items-center">
              <label className="mr-2" htmlFor="isFlapOpening">
                Is Flap Opening?
              </label>
              <input
                type="checkbox"
                name="isFlapOpening"
                checked={product.isFlapOpening}
                onChange={handleChange}
                className="mr-2 leading-tight"
              />
            </div>
            {product.isFlapOpening && (
              <div className="space-y-2">
                {product.flapOpenings.map((type, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={type}
                      onChange={(e) => handleFlapOpeningChange(index, e)}
                      placeholder="Enter flap opening"
                      className="w-full p-2 border rounded-md focus:outline-none"
                    />
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeFlapOpeningField(index)}
                        className="bg-red-500 text-white p-2 rounded-md"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addFlapOpeningField}
                  className="bg-blue-500 text-white p-2 rounded-md"
                >
                  Add Flap Opening
                </button>
              </div>
            )}
            <div className="flex items-center">
              <label className="mr-2" htmlFor="isPrinting">
                Is Printing?
              </label>
              <input
                type="checkbox"
                name="isPrinting"
                checked={product.isPrinting}
                onChange={handleChange}
                className="mr-2 leading-tight"
              />
            </div>
            {product.isPrinting && (
              <div className="space-y-2">
                {product.printings.map((type, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={type}
                      onChange={(e) => handlePrintingChange(index, e)}
                      placeholder="Enter Printing"
                      className="w-full p-2 border rounded-md focus:outline-none"
                    />
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removePrintingField(index)}
                        className="bg-red-500 text-white p-2 rounded-md"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addPrintingField}
                  className="bg-blue-500 text-white p-2 rounded-md"
                >
                  Add Printing
                </button>
              </div>
            )}
            <div className="flex items-center">
              <label className="mr-2" htmlFor="isLaminationType">
                Is Lamination Type?
              </label>
              <input
                type="checkbox"
                name="isLaminationType"
                checked={product.isLaminationType}
                onChange={handleChange}
                className="mr-2 leading-tight"
              />
            </div>
            {product.isLaminationType && (
              <div className="space-y-2">
                {product.laminationTypes.map((type, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={type}
                      onChange={(e) => handleLaminationTypeChange(index, e)}
                      placeholder="Enter laminationa type"
                      className="w-full p-2 border rounded-md focus:outline-none"
                    />
                    {index > 0 && (
                      <button
                        type="button"
                        onClick={() => removeLaminationTypeField(index)}
                        className="bg-red-500 text-white p-2 rounded-md"
                      >
                        Remove
                      </button>
                    )}
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addLaminationTypeField}
                  className="bg-blue-500 text-white p-2 rounded-md"
                >
                  Add Lamination Type
                </button>
              </div>
            )}
            {/* Product Name */}

            <div>
              {/* <label className="block text-gray-700 mb-2" htmlFor="videoUrl">Video URL</label> */}
              <input
                type="text"
                id="videoUrl"
                name="videoUrl"
                value={product.videoUrl}
                onChange={handleChange}
                className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                placeholder="Enter video URL"
              />
            </div>
            {/* Price Tiers */}
            <div>
              {/* <label className="block text-gray-700 mb-2">Price Tiers</label> */}
              {product.priceTiers.map((tier, index) => (
                <div key={index} className="flex space-x-4 mb-2">
                  <input
                    type="number"
                    name="quantity"
                    placeholder="Quantity"
                    value={tier.quantity}
                    onChange={(e) => handlePriceTierChange(index, e)}
                    className="w-1/2 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                  />
                  <input
                    type="number"
                    name="price"
                    placeholder="Price"
                    value={tier.price}
                    onChange={(e) => handlePriceTierChange(index, e)}
                    className="w-1/2 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-gray-800"
                  />
                  {index > 0 && (
                    <button
                      type="button"
                      onClick={() => removePriceTier(index)}
                      className="bg-red-500 text-white p-2 rounded-md"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={addPriceTier}
                className="bg-blue-500 text-white p-2 rounded-md"
              >
                Add Price Tier
              </button>
            </div>

            <button
              type="submit"
              className="w-1/6 py-3 bg-gray-800 text-white font-bold rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-800"
              disabled={loading} // Disable button when loading
            >
              {loading
                ? "Submitting..."
                : isEditing
                  ? "Update Product"
                  : "Add Product"}
            </button>
            {isEditing && (
              <button
                onClick={clearForm}
                className=" ml-4 bg-gray-500 hover:bg-gray-400 text-white py-3 px-4 font-bold rounded-md"
              >
                Cancel
              </button>
            )}
          </form>
        </div>
      </div>
      <div className="container mx-auto p-4">
        <h2 className="text-2xl font-bold mb-4">Product Table</h2>

        {/* Search Box */}
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by name, category, or subcategory..."
          className="mb-4 p-2 border border-gray-300 rounded-md w-full"
        />

        {/* Product Table */}
        <table className="min-w-full table-auto text-center">
          <thead>
            <tr className="bg-gray-800 text-white">
              <th className="p-2">Sr No.</th>
              <th className="p-2">Name</th>
              <th className="p-2">Category</th>
              <th className="p-2">Subcategory</th>
              <th className="p-2">Date</th>
              <th className="p-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.map((product, index) => (
              <tr key={product._id} className="border-t">
                <td className="p-2">{index + 1}</td>
                <td className="p-2 text-start">{product.name}</td>
                <td className="p-2">{product?.category?.name || "Generic"}</td>
                <td className="p-2">{subCategoryNames[product.subCategory] || "N/A"}</td>
                <td className="py-2">
                  {new Date(product.createdAt).toLocaleDateString()}
                </td>
                <td className="p-2 space-x-4">
                  <button
                    onClick={() => handleAdd(product)}
                    className="bg-gray-800 hover:bg-gray-700 text-white p-2 rounded-md"
                  >
                    Add
                  </button>
                  <button
                    onClick={() => handleView(product._id)}
                    className="bg-blue-500 hover:bg-blue-700 text-white p-2 rounded-md"
                  >
                    View
                  </button>
                  <button
                    onClick={() => handleEdit(product)}
                    className="bg-yellow-400 hover:bg-yellow-600 text-white p-2 rounded-md"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(product._id)}
                    className="bg-red-500 hover:bg-red-700 text-white p-2 rounded-md"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AddProduct;
